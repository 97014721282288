<template>
  <div class="c-layout-header">
    <div class="header-box">
      <div class="logo-box font3">
        <div class="logo">
            <logo></logo>
        </div>
      </div>
      <div class="route-tab">
        <el-menu
            :default-active="activeIndex2"
            text-color="#fff"
            class="headers"
            mode="horizontal"
            @select="handleSelect">
          <el-menu-item index="home"><span class="fontw">首页</span></el-menu-item>
<!--          <el-menu-item index="/djyl">党建引领</el-menu-item>-->
          <el-submenu index="3">
            <template slot="title" ><span class="fontw">企业服务</span></template>
            <template v-for="(item,index) in menu">
              <el-menu-item v-if="!item.menu" :index="item.src">{{ item.title }}</el-menu-item>
              <el-submenu v-else :index="item.src">
                <template slot="title">{{ item.title }}</template>
                <el-menu-item v-for="(it,i) in item.menu" :key="i" :index="it.src">{{ it.title }}</el-menu-item>
              </el-submenu>
            </template>

          </el-submenu>
          <el-submenu index="4">
            <template slot="title" ><span class="fontw">产品供需信息</span></template>
            <template v-for="(item,index) in menu1">
              <el-menu-item v-if="!item.menu" :index="item.src">{{ item.title }}</el-menu-item>
              <el-submenu v-else :index="item.src">
                <template slot="title">{{ item.title }}</template>
                <el-menu-item v-for="(it,i) in item.menu" :key="i" :index="it.src">{{ it.title }}</el-menu-item>
              </el-submenu>
            </template>
          </el-submenu>
          <el-menu-item index="/map"><span class="fontw">产业链地图</span></el-menu-item>
          <el-submenu index="5">
            <template slot="title" ><span class="fontw">智能制造</span></template>
            <template v-for="(item,index) in menu2">
              <el-menu-item v-if="!item.menu" :index="item.src">{{ item.title }}</el-menu-item>
              <el-submenu v-else :index="item.src">
                <template slot="title">{{ item.title }}</template>
                <el-menu-item v-for="(it,i) in item.menu" :key="i" :index="it.src">{{ it.title }}</el-menu-item>
              </el-submenu>
            </template>
          </el-submenu>
          <el-menu-item index="/biaoshi1"><span class="fontw">标识申领</span></el-menu-item>
          <el-menu-item index="about"><span class="fontw">关于我们</span></el-menu-item>
        </el-menu>
<!--        <el-input type="text" v-model="keyword" v-if="activeIndex2=='home'"  style="width:169px" placeholder="请输入关键字搜索">-->
<!--          <el-button slot="append" icon="el-icon-search" class="btn"  @click="gotosearch()"></el-button>-->
<!--        </el-input>-->
        <div class="login-user1 not_login" v-if="!userInfo">
          <div @click="login">登录/注册</div>
        </div>
        <div v-else class="login-user2">
          <el-dropdown @command="handleCommand">
            <div class="user text-overflow">您好，{{ userInfo.name }}</div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="exit">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div>|</div>
          <div class="admin" @click="gotoAdmin">
            <i class="el-icon-s-home"></i>
            后台管理
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from '../utils/config'
import Cookies from "js-cookie";
import logo from "./logo.vue"

export default {
  name: "headers",
  data() {
    return {
      keyword:'',
      logo:require('../assets/home/ff01.png'),
      activeIndex2:this.common.getSessionStorage('tabstatus')||'home',
      userInfo:  this.common.getSessionStorage('userInfo'),
      menu:[{title:'产业政策', src:'zcfw',menu:[{title:'政策汇集', src:'zchj'},{title:'政策解答', src:'zcjd'},{title:'项目申报', src:'xmsb'}]},
        {title:'物流服务', src:'logistics'},
        {title:'园区管理', src:'parkindex'},
        {title:'专家咨询', src:'zjzx'},
        {title:'在线培训', src:'zxpx'},
        {title:'融资服务', src:'financing'},
        {title:'人力资源', src:'rlzy'},
        {title:'中介服务', src:'zxfw'}],
      menu1:[{title:'供应链信息', src:'gxjy'},{title:'招投标', src:'zhaobiao'},{title:'采购信息', src:'caigou'},{title:'销售信息', src:'kucun'}],
      menu2:[
        {title:'企业数字化', src:'compnumber'},
        {title:'商城系统', src:'sysc'},
        {title:'仓储物流管理', src:'wuliu'},
        {title:'生产管理', src:'shengchan'},
        {title:'设备管理', src:'shebei'},
        {title:'数字孪生', src:'shuzi'},
        {title:'低代码开发', src:'didaima'},
        {title:'网络安全', src:'anquan'},
        {title:'标识解析', src:'biaoshi'},
        {title:'解决方案', src:'jjfz'}
      ]
    };
  },
  components:{
    logo
  },
  created() {
    if(this.common.getSessionStorage('userInfo')){
      this.loadinfo()
    }
  },
  watch:{
    $route: {
      handler: function(val, oldVal){
        this.keyword=''
        this.common.addSessionStorage('tabstatus',val.name)
        this.activeIndex2=this.common.getSessionStorage('tabstatus')|| 'home'
      },
      deep: true
    }
  },
  methods: {
    loadinfo(){
      this.$api.index.userinfo().then((res)=>{
        // Cookies.set('saber-access-token', res.data.access_token)
        // Cookies.set('Tenant-Id', res.data.tenant_id)
        this.common.addSessionStorage('userInfo',res.data)
        this.userInfo=res.data
      })
    },
    gotoAdmin() {
      window.open(config.adminhref + '#/wel/index', '_blank')
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
       if(key=='/znzz'){
        if(!this.userInfo){
          this.$message.error('请先登录!');
          this.login()
        }else{
        }
      }else if(key=='/tzhh'){
        if(!this.userInfo){
          this.$message.error('请先登录!');
          this.login()
        }else{
        }
      }else if(key=='/biaoshi1'){
         //window.open('http://27.128.231.193:8000/snms/ui/login', '_blank')
        this.$router.push({
          path:'biaoshi1'
        })
      }else{
         if(key=='financing'){
           const routeData = this.$router.resolve({ name: 'financing' });
           window.open(routeData.href, '_blank');
         }else{
           this.$router.push({
             path:key
           })
         }
      }
    },
    login(){
      this.$router.push({
        path:'/login'
      })
    },
    gotosearch(){
      this.$router.push({
        path:'/search',
        query:{
          keyword:this.keyword
        }
      })
    },
    handleCommand(command){
        if(command=='exit'){
          this.$api.index.logout().then((res)=>{
             this.common.removeSessionStorage('userInfo')
             Cookies.remove('saber-access-token')
             this.$router.go(0)
            window.location.href = `${process.env.VUE_APP_AUTH_URL}/sso/logout?redirectUrl=${window.location.href}`
          },(err)=>{
            this.common.removeSessionStorage('userInfo')
            this.$router.go(0)
          })
        }else if(command=='admin'){
          this.gotoAdmin();
        }
    }
  },
}
</script>

<style scoped lang="scss">
.user{
  color: #fff;
}
.fontw{
  font-weight: bolder!important;
  letter-spacing: 2px;
}
/deep/ .el-input__inner{
  background: rgba(255,255,255,0.5)!important;
  height: 30px;
  line-height: 30px;
  border: none;
}
/deep/ .el-submenu__title{
  font-size: 16px;
}
/deep/ .el-input__inner::placeholder{
  color: #666666;
}
/deep/ .el-input-group__append{
  background: rgba(255,255,255,0.5)!important;
  border: none;
}

.c-layout-header {
  width: 1920px;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translate(-50%);
  height: 70px;
  width: 100%;
  z-index: 2;

  .header-box {
    height: 70px;
    width: 100%;
    min-width: 1200px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 111, 240, 0);
      filter: blur(3px);
    }
  }

  .logo-box {
    margin-left: 40px;
    font-size: 18px;
    position: absolute;

    .logo {
      width: 302px;
      height: 21px;
    }
  }

  .route-tab {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    right: 0;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .login-user1 {
    padding: 0 10px;
    margin-left: 15px;
    margin-right: 40px;
    width: 175px;
    height: 32px;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    color: #fff;
    background: rgba(255, 226, 168, 0);
    border: 1px solid rgba(255, 255, 255, .9);
    border-radius: 16px;
    cursor: pointer;
  }

  .login-user2 {
    display: flex;
    gap: 10px;
    padding: 10px 20px;
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
    transition: all 300ms;
    font-size: 14px;
    border: 1px solid rgba(255, 255, 255, .9);
    margin-left: 15px;
    margin-right: 10px;

    .user {
      max-width: 150px;
      text-align: center;
      color: #fff;
      background: rgba(255, 226, 168, 0);
    }

    .admin {
      color: #fff;
      border-radius: 10px;
      cursor: pointer;
      transition: all 300ms;

      &:hover {
        color: #0a28b4;
      }
    }
  }

  .not_login {
    position: relative;
    font-size: 16px;
    width: 100px;
  }


}
</style>
